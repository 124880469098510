import React, { useEffect, useState, Fragment } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Table, message, Button, Row, Col, Input, Modal, Form, InputNumber, Select, Switch, Tag, Upload, Image, UploadProps } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import Ajax from "../../../api/Ajax";
import "./index.less";
import LocalStore from "../../../utils/LocalStore";

interface ProductData {
    id: number;
    seq: number;
    name: string;
    serviceId: number;
    modelSeq: number;
    serviceName: string;
    serviceTypeId: number;
    serviceTypeName: string;
    price: string;
    remark: string;
    details: string;
    discount: number;
    fileName: string;
    longFileName: string;
    createTime: Date;
    productSpecs: any[];
}
interface Service {
    id: number;
    name: string;
}
interface ServiceType {
    id: number;
    name: string;
}
interface SelectOption {
    label: string;
    value: number | string;
}

const ProductData = props => {
    const { Search } = Input;
    const { TextArea } = Input;
    const [data, setData] = useState<ProductData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [statusVisible, setStatusVisible] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<string>('新增产品');
    const [currentRecord, setCurrentRecord] = useState<ProductData | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [serviceNameOption, setServiceNameOption] = useState<SelectOption[]>([]);
    const [serviceTypeOption, setServiceTypeOption] = useState<SelectOption[]>([]);
    const [fileList, setFileList] = useState<any[]>([]);
    const [longFileList, setLongFileList] = useState<any[]>([]);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const canDelete = selectedRowKeys.length > 0;
    const canModifyStatus = selectedRowKeys.length > 0;
    const [modelTypeLabel, setModelTypeLabel] = useState<string>('');
    const [productSpecs, setProductSpecs] = useState([{
        specContent: '',
        standardPrice: '',
        groupPrice: '',
        id: Math.random()
    }]);
    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<ProductData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '所属服务',
            dataIndex: 'serviceName',
            key: 'serviceName',
            align: 'center',
        },
        {
            title: '所属类型',
            dataIndex: 'serviceTypeName',
            key: 'serviceTypeName',
            align: 'center',
        },
        {
            title: '产品名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '产品描述',
            key: 'details',
            dataIndex: 'details',
            align: 'center',
        },
        {
            title: '产品价格',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
        },
        {
            title: '产品折扣',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
        },
        {
            title: '固定模块',
            dataIndex: 'modelSeq',
            key: 'modelSeq',
            align: 'center',
            render: (modelSeq) => modelTypeOptions[modelSeq] || '',
        },
        {
            title: '产品图片',
            dataIndex: 'fileName',
            key: 'fileName',
            align: 'center',
        },
        {
            title: '产品详情图',
            dataIndex: 'longFileName',
            key: 'longFileName',
            align: 'center',
        },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            align: 'center',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (status) => (
                <Tag color={status === '1' ? 'green' : 'red'}>
                    {status === '1' ? '上架' : '下架'}
                </Tag>
            )
        },
        {
            title: '操作',
            fixed: 'right',
            width: 180,
            render: (text, record) => (
                <Fragment>
                    <Button size='small' type='link' onClick={() => handleEdit(record)}>编辑</Button>
                </Fragment>
            ),
        },
    ];
    const modelTypeOptions: { [key: number]: string } = {
        11: '顶部',
        12: '底部',
        41: '二排左',
        42: '二排中',
        43: '二排右',
        21: '左排上',
        22: '左排下',
        31: '右排上',
        32: '右排中',
        33: '右排下'
    };
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetch(pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        fetchServices(); // 拉取服务数据
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const fieldData = productSpecs.map((spec) => ({
            specContent: spec.specContent || '',
            standardPrice: spec.standardPrice || '',
            groupPrice: spec.groupPrice || '',
        }));
        form.setFieldsValue({
            productSpecs: fieldData,
        });
    }, [productSpecs]);

    const fetchServices = () => {
        const storedServices = LocalStore.get('service') || [];
        const options = storedServices.map((service: Service) => ({
            label: service.name,
            value: service.id,
        }));
        setServiceNameOption(options);
    };
    const fetchServiceTypes = (serviceId?: number) => {
        if (!serviceId) {
            setServiceTypeOption([]); // 如果没有选择服务，则清空服务类型选项
            return;
        }
        Ajax.get(`/store-service-type/list-by-serviceids?ids=${serviceId}`).then(body => {
            const serviceTypes = body.data;
            const options = serviceTypes.map((serviceType: Service) => ({
                label: serviceType.name,
                value: serviceType.id,
            }));
            setServiceTypeOption(options);
            LocalStore.put('service-type', serviceTypes);
        });
    };
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/product/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增产品');
        setVisible(true);
        setIsAdding(true);
        form.resetFields();
        setFileList([]);
        setLongFileList([]);
        setCurrentRecord(null);
        setServiceTypeOption([]);
        setProductSpecs([
            {
                specContent: '',
                standardPrice: '',
                groupPrice: '',
                id: Math.random(),
            },
        ]);
    };
    /**
     * @description: 编辑
     * @param {ProductData} record
     * @return {*}
     */
    const handleEdit = (record: ProductData) => {
        setModalTitle('编辑产品');
        setVisible(true);
        setIsAdding(false);
        form.setFieldsValue({
            seq: record.seq,
            name: record.name,
            serviceId: record.serviceId,
            serviceName: record.serviceName,
            serviceTypeId:  record.serviceTypeId,
            serviceTypeName:  record.serviceTypeName,
            price:  record.price,
            details: record.details,
            remark: record.remark,
            modelSeq: record.modelSeq
        });
        setCurrentRecord(record);
        fetchServiceTypes(record.serviceId);

        // 图片回显
        if (record.fileName) {
            const fileUrl = `https://catmorning.com/service/prod/${record.fileName}`; // 生产环境
            // const fileUrl = `http://1.94.124.102:8088//service/public/${record.fileName}`;  // 开发环境
            const newFileList = [{
                uid: record.id, 
                name: record.fileName,
                status: 'done',
                url: fileUrl,
            }];
            setFileList(newFileList);
        } else {
            setFileList([]);
        }
        if (record.longFileName) {
            const longFileUrl = `https://catmorning.com/service/prod/${record.longFileName}`;
            // const longFileUrl = `http://1.94.124.102:8088//service/public/${record.longFileName}`;  // 开发环境
            const newLongFileList = [{
                uid: record.id + 1,
                name: record.longFileName,
                status: 'done',
                url: longFileUrl,
            }];
            setLongFileList(newLongFileList);
        } else {
            setLongFileList([]);
        }
        const specs = record.productSpecs && record.productSpecs.length > 0 
        ? record.productSpecs.map((spec, index) => ({
            specContent: spec.specContent || '',
            standardPrice: spec.standardPrice || '',
            groupPrice: spec.groupPrice || '',
            id: spec.id || Math.random(),
        }))
        : [ 
            {
                specContent: '',
                standardPrice: '',
                groupPrice: '',
                id: Math.random(),
            }
        ];
        setProductSpecs(specs);
    };
    /**
     * @description: 确定
     * @return {*}
     */
    const handleOk = async () => {
        const values = await form.validateFields();
        values.status = values.status ? '0' : '1';
        values.productSpecs = productSpecs.map((spec, index) => ({
            specContent: values.productSpecs[index].specContent,
            standardPrice: values.productSpecs[index].standardPrice,
            groupPrice: values.productSpecs[index].groupPrice,
            id: spec.id < 1 ? 0 : spec.id, // 小于1表示新增的数据
        }));
        values.fileName = form.getFieldValue('fileName');  // 产品图片
        values.longFileName = form.getFieldValue('longFileName');  // 产品详情图
        // 如果是编辑，则进行编辑 API 请求
        if (currentRecord) {
            // 编辑
            values.id = currentRecord.id
            Ajax.post('/product/update', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        } else {
            // 新增
            values.serviceName = getServiceName(values.serviceId)
            values.serviceTypeName = getServiceTypName(values.serviceTypeId)
            Ajax.post('/product/create', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        }
        setFileList([]);
        setLongFileList([]);
        setVisible(false);
    };
    /**
     * @description: 取消
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: ProductData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认删除
     * @return {*}
     */    
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/product/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const getServiceName = (resourceId: number): string => {
        const resource = LocalStore.get('service');
        let title = '';
        resource.forEach(e => {
            if (e.id === resourceId) {
                title = e.name;
                return title;
            }
        })
        return title;
    }
    const getServiceTypName = (resourceId: number): string => {
        const resource = LocalStore.get('service-type');
        let title = '';
        resource.forEach(e => {
            if (e.id === resourceId) {
                title = e.name;
                return title;
            }
        })
        return title;
    }
    /**
     * @description: 确认状态修改
     * @return {*}
     */    
    const statusHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const statusValue = switchChecked ? '1' : '0';
            const body = await Ajax.post(`/product/status?ids=${params}&status=${statusValue}`);
            if (body.code === 0) {
                message.success(body.msg);
                setStatusVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("状态修改失败");
        } finally {
            setStatusVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const statusCancel = () => {
        setStatusVisible(false);
        setSwitchChecked(true);
    };
    const handleSwitchChange = (checked) => {
        setSwitchChecked(checked);
    };
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>选择图片</div>
        </div>
    );
    const fileChange: UploadProps['onChange'] = ({ fileList: newFileList, file }) => {
        setFileList(newFileList);
        if (file.status === 'done' && file.response) {
            if (file.response.code === 0) {
                form.setFieldsValue({ fileName: file.response.data });
                message.success(`文件上传成功: ${file.response.data}`);
            } else {
                message.error(`文件上传失败: ${file.response.msg}`);
            }
        }
    };
    const longFileChange: UploadProps['onChange'] = ({ fileList: newFileList, file }) => {
        setLongFileList(newFileList);
        if (file.status === 'done' && file.response) {
            if (file.response.code === 0) {
                form.setFieldsValue({ longFileName: file.response.data });
                message.success(`文件上传成功: ${file.response.data}`);
            } else {
                message.error(`文件上传失败: ${file.response.msg}`);
            }
        }
    };
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true); // 加载状态设为 true
        try {
            const response = await Ajax.post('/product/page/' + pagination.current, { name: value.trim() }); // 发送请求
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 }); // 更新分页信息
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false); // 加载状态设为 false
        }
    };
    const handleAddSpec = () => {
        setProductSpecs([...productSpecs, {
            specContent: '',
            standardPrice: '',
            groupPrice: '',
            id: Math.random(), // 随机生成一个唯一的 id
        }]);
    };
    
    const handleRemoveSpec = (id: number) => {
        setProductSpecs(productSpecs.filter(spec => spec.id !== id));
    };
    
    const handleSpecChange = (id: number, field: string, value: string | number) => {
        const updatedSpecs = productSpecs.map((spec) => {
            if (spec.id === id) {
                return { ...spec, [field]: value };
            }
            return spec;
        });
        setProductSpecs(updatedSpecs);
    
        // 同步到表单状态
        const fieldData = updatedSpecs.map((spec) => ({
            specContent: spec.specContent,
            standardPrice: spec.standardPrice,
            groupPrice: spec.groupPrice,
        }));
        form.setFieldsValue({
            productSpecs: fieldData,
        });
    };
    return (
        <div>
            <h1>产品管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入角色名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button type="primary" className="buttonSpace" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
                <Button type="primary" className="buttonSpace" disabled={!canModifyStatus} onClick={() => { setSwitchChecked(true); setStatusVisible(true); }}>状态修改</Button>
                <Button className="buttonSpace" type='primary' onClick={handleAdd}>新增</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除服务名称【{selectedRowKeys.map(key => {
                        const user = data.find(user => user.id === key);
                        return user ? user.name : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal
                title="状态修改"
                visible={statusVisible}
                cancelText='取消'
                okText='确认'
                onOk={statusHandleOk}
                onCancel={statusCancel}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>产品状态：</span>
                    <Switch 
                        checked={switchChecked} 
                        onChange={handleSwitchChange} 
                        checkedChildren="上架" 
                        unCheckedChildren="下架" 
                    />
                </div>
            </Modal>
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }} initialValues={{price: 0, discount: 0}}>
                    <Row gutter={24}>
                        {currentRecord && (
                            <Col span={12}>
                                <Form.Item name="seq" label="序号" rules={[{ required: false, message: '请输入序号' }]}>
                                    <InputNumber step={1} placeholder="请输入序号" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item name="serviceId" label="所属服务" rules={[{ required: true, message: '请选择所属服务' }]}>
                                <Select placeholder="请选择所属服务" allowClear options={serviceNameOption} onChange={(value) => {form.setFieldsValue({ serviceTypeId: undefined }); fetchServiceTypes(value); }}  disabled={!!currentRecord}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="serviceTypeId" label="所属类型" rules={[{ required: true, message: '请选择所属类型' }]}>
                                <Select placeholder="请选择所属类型" allowClear options={serviceTypeOption} disabled={!!currentRecord}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="name" label="产品名称" rules={[{ required: true, message: '请输入产品名称' }]} >
                                <Input placeholder="请输入产品名称" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item name="price" label="产品价格" rules={[{ required: true, message: '请输入产品价格' }]}>
                                <InputNumber step={0.01} precision={2} addonAfter="元/小时" placeholder="请输入产品价格" style={{width: '100%' }} />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item name="discount" label="产品折扣" rules={[{ required: true, message: '请输入产品折扣' }]}>
                                <InputNumber step={0.01} precision={2} placeholder="请输入产品折扣" style={{width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="modelSeq" label="固定模块" rules={[{ required: false, message: '请输入固定模块' }]}>
                                <Select placeholder="请输入固定模块" allowClear onChange={value => setModelTypeLabel(modelTypeOptions[value])}>
                                    {Object.entries(modelTypeOptions).map(([key, label]) => (
                                        <Select.Option key={key} value={parseInt(key)}>{label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="details" label="产品描述" rules={[{ required: true, message: '请输入产品描述' }]}>
                                <TextArea rows={4} placeholder="请输入产品描述"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="remark" label="备注" rules={[{ required: false, message: '请输入备注信息' }]}>
                                <TextArea rows={4} placeholder="请输入备注信息"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="fileName" label="产品图片">
                                <div>
                                    <Upload
                                        action="/minio/file/upload"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={fileChange}
                                    >
                                        {fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                    <Image
                                        src={previewImage}
                                        style={{ display: 'none' }}
                                        preview={{ visible: previewOpen,onVisibleChange: (visible) => setPreviewOpen(visible) }}
                                    />
                                    {isAdding && <span style={{ color: 'red', display: 'block' }}>推荐尺寸 375*250</span>}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="longFileName" label="产品详情图">
                                <div>
                                    <Upload
                                        action="/minio/file/upload"
                                        listType="picture-card"
                                        fileList={longFileList}
                                        onPreview={handlePreview}
                                        onChange={longFileChange}
                                    >
                                        {longFileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                    <Image
                                        src={previewImage}
                                        style={{ display: 'none' }}
                                        preview={{ visible: previewOpen, onVisibleChange: (visible) => setPreviewOpen(visible) }}
                                    />
                                    {isAdding && <span style={{ color: 'red', display: 'block' }}>推荐尺寸 375*250</span>}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="产品规格">
                                {productSpecs.map((spec, index) => (
                                    <Row key={spec.id} gutter={24} style={{ marginBottom: 12 }}>
                                        <Col span={7}>
                                        <Form.Item  name={['productSpecs', index, 'specContent']}  label="规格" labelCol={{ span: 6 }} rules={[{ required: true, message: '请输入规格' }]} >
                                            <Input placeholder="请输入规格" value={spec.specContent} onChange={(e) => handleSpecChange(spec.id, 'specContent', e.target.value)} />
                                        </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item name={['productSpecs', index, 'standardPrice']} label="原价" labelCol={{ span: 6 }} rules={[{ required: true, message: '请输入原价' }]} >
                                                <InputNumber placeholder="请输入原价" value={spec.standardPrice} onChange={(value) => handleSpecChange(spec.id, 'standardPrice', value ?? "")} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item  name={['productSpecs', index, 'groupPrice']} label="团购价" labelCol={{ span: 8 }} rules={[{ required: true, message: '请输入团购价' }]} >
                                                <InputNumber placeholder="请输入团购价" value={spec.groupPrice} onChange={(value) => handleSpecChange(spec.id, 'groupPrice', value ?? "")} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Button type="dashed" danger onClick={() => handleRemoveSpec(spec.id)}>
                                                删除
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button type="dashed" block onClick={handleAddSpec}>添加</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default ProductData;