import React, {useEffect, useState} from 'react';
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {Button, message, Modal, Space, Table, Tag, TreeSelect, Form, Row, Col, Input} from "antd";
import LocalStore from "../../../utils/LocalStore";
import Ajax from "../../../api/Ajax";
import {getResourceTree} from "../../../common/array";
import Auth from "../../../common/Auth";

/**
 * 从后端接受的角色数据
 */
export interface Role {
    id: number,
    name: string,
    resourceIds: number[],
    menusIds: number[],
    operateIds: number[]
}

/**
 * 根据权限id获取对应的权限资源名称
 * @param resourceId 权限资源id
 */
const getResourceTreeName = (resourceId: number): string => {
    // 根据存储在本地的字典进行名称判断
    const resource = LocalStore.get('resource');
    let title = '';
    resource.forEach(e => {
        if (e.id === resourceId) {
            title = e.name;
            return title;
        }
    })
    return title;
}

/**
 * 根据权限id判断是否为指定权限类型
 * @param resourceId 权限资源id
 * @param type 资源类型，0为菜单，1为操作
 */
const isResourceType = (resourceId: number, type: number): boolean => {
    // 根据存储在本地的字典进行名称判断
    const resource = LocalStore.get('resource');
    let flag = false;
    resource.forEach(e => {
        if (e.type === type && e.id === resourceId) {
            flag = true;
            return flag;
        }
    })
    return flag;
}

const Role = props => {
    const { Search } = Input;
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<Role>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const canDelete = selectedRowKeys.length > 0;
    const [modalTitle, setModalTitle] = useState<string>('新增角色');
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [currentRecord, setCurrentRecord] = useState<Role | null>(null);
    const { TextArea } = Input;

    // 表设置
    const columns: ColumnsType<Role> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
        },
        {
            title: '角色名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '页面权限',
            key: 'menu',
            dataIndex: 'resourceIds',
            align: 'center',
            ellipsis: { showTitle: false },
            width: '30%',
            render: resourceIds => (
                <>
                    {resourceIds
                        .filter(e => isResourceType(e, 0))
                        .map(resourceId => {
                            return (
                                <Tag color='geekblue' key={resourceId}>
                                    {getResourceTreeName(resourceId)}
                                </Tag>
                            );
                        })}
                </>
            ),
        },
        {
            title: '操作权限',
            key: 'operate',
            dataIndex: 'resourceIds',
            align: 'center',
            ellipsis: { showTitle: false },
            width: '30%',
            render: resourceIds => (
                <>
                    {resourceIds
                        .filter(e => isResourceType(e, 1))
                        .map(resourceId => {
                            return (
                                <Tag color='green' key={resourceId}>
                                    {getResourceTreeName(resourceId)}
                                </Tag>
                            );
                        })}
                </>
            ),
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            width: 100,
            render: (text, role) => (
                <Space size="small">
                    <Auth resourceId={2003}>
                        <Button size='small' type='link' onClick={() => handleEdit(role)}>编辑</Button>
                    </Auth>
                </Space>
            ),
        },
    ];

    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/role/page/' + pagination.current).then(body => {
            const roles = body.data.records;
            roles.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            // 给数据设置好key，好渲染组件
            setRoles(roles);
            setPagination({...pagination, total: body.data.total});
            setLoading(false);
        });
    }

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        // eslint-disable-next-line
    }, []);

    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({...pagination});
        fetch(pagination);
    };

    // 当权限树状图更改时
    const menuTreeOnChange = value => {
        const tempRole = {...selectedRole};
        tempRole.menusIds = value;
        tempRole.resourceIds = tempRole.operateIds.concat(value);
        setSelectedRole(tempRole);
    }
    // 当权限树状图更改时
    const operateTreeOnChange = value => {
        const tempRole = {...selectedRole};
        tempRole.operateIds = value;
        tempRole.resourceIds = tempRole.menusIds.concat(value);
        setSelectedRole(tempRole);
    }
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/role/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };

    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: Role) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增角色');
        setVisible(true);
        form.resetFields();
        setCurrentRecord(null);
    };
    /**
     * @description: 编辑
     * @param {Role} record
     * @return {*}
     */
    const handleEdit = (record: Role) => {
        record.menusIds = record.resourceIds.filter(i => isResourceType(i, 0));
        record.operateIds = record.resourceIds.filter(i => isResourceType(i, 1));
        setModalTitle('编辑角色');
        setVisible(true);
        setSelectedRole(record);
        form.setFieldsValue({
            name: record.name,
            menusIds: record.menusIds,
            operateIds: record.operateIds,
            // remark: record.remark,
        });
        setCurrentRecord(record);
    };
    /**
     * @description: 新增/修改弹窗-确定事件
     * @return {*}
     */
    const handleOk = async () => {
        const values = await form.validateFields();
        values.resourceIds = [...(values.menusIds || []), ...(values.operateIds || [])];
        delete values.menusIds;
        delete values.operateIds;
        if (currentRecord) {
            // 编辑
            values.id = currentRecord.id
            Ajax.post('/role/update', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        } else {
            // 新增
            Ajax.post('/role/create', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        }
        setVisible(false);
    };
    /**
     * @description: 新增/修改弹窗-取消事件
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/role/page/' + pagination.current, { name: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setRoles(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <h1>角色管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入角色名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Auth resourceId={2002}>
                    <Button className="buttonSpace" type="primary" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
                </Auth>
                <Auth resourceId={2001}>
                    <Button className="buttonSpace" type="primary" onClick={handleAdd}>新增</Button>
                </Auth>
            </div>
            <Table
                dataSource={roles}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除【{selectedRowKeys.map(key => {
                        const role = roles.find(role => role.id === key);
                        return role ? role.name : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="角色名称" name="name" rules={[{required: true, message: '请输入角色名称'}, {max: 12, message: '最多输入12个字符'}]}>
                                <Input placeholder='请输入角色名称'/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="页面权限" name="menusIds">
                                <TreeSelect
                                    treeData={getResourceTree(0)}
                                    style={{width: '100%'}}
                                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                    treeCheckable={true}
                                    treeDefaultExpandAll={true}
                                    placeholder='点击选择页面权限'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="操作权限" name="operateIds">
                                <TreeSelect
                                    treeData={getResourceTree(1)}
                                    style={{width: '100%'}}
                                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                    treeCheckable={true}
                                    treeDefaultExpandAll={true}
                                    placeholder='点击选择操作权限'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="remark" label="备注" rules={[{ required: false, message: '请输入备注信息' }]}>
                                <TextArea rows={4} placeholder="请输入备注信息"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default Role;
