import React, { useEffect, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Table, message, Button, Modal, Switch, Tag, Input } from "antd";
import Ajax from "../../api/Ajax";
import "./index.less";

interface ProductData {
    id: number;
    seq: number;
    name: string;
    customerId: number;
    grade: string;
    details: string;
    status: string;
    createTime: Date;
}
const ProductData = props => {
    const { Search } = Input;
    const [data, setData] = useState<ProductData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [statusVisible, setStatusVisible] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [switchChecked, setSwitchChecked] = useState(false);
    const canModifyStatus = selectedRowKeys.length > 0;
    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<ProductData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '订单号(ID)',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: '评价人',
            dataIndex: 'customerId',
            key: 'customerId',
            align: 'center',
        },
        {
            title: '评分',
            dataIndex: 'grade',
            key: 'grade',
            align: 'center',
        },
        {
            title: '评价',
            key: 'details',
            dataIndex: 'details',
            align: 'center',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (status) => (
                <Tag color={status === '1' ? 'green' : 'red'}>
                    {status === '1' ? '展示' : '不展示'}
                </Tag>
            )
        }
    ];
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetch(pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        // eslint-disable-next-line
    }, [])
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/eval/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: ProductData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认状态修改
     * @return {*}
     */    
    const statusHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const statusValue = switchChecked ? '1' : '0';
            const body = await Ajax.post(`/eval/status?ids=${params}&status=${statusValue}`);
            if (body.code === 0) {
                message.success(body.msg);
                setStatusVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("状态修改失败");
        } finally {
            setStatusVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const statusCancel = () => {
        setStatusVisible(false);
        setSwitchChecked(true);
    };
    const handleSwitchChange = (checked) => {
        setSwitchChecked(checked);
    };
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/eval/page/' + pagination.current, { id: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <h1>评价管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入订单号" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button type="primary" className="buttonSpace" disabled={!canModifyStatus} onClick={() => { setSwitchChecked(false); setStatusVisible(true); }}>状态修改</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="状态修改"
                visible={statusVisible}
                cancelText='取消'
                okText='确认'
                onOk={statusHandleOk}
                onCancel={statusCancel}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>评价状态：</span>
                    <Switch 
                        checked={switchChecked} 
                        onChange={handleSwitchChange} 
                        checkedChildren="展示" 
                        unCheckedChildren="不展示" 
                    />
                </div>
            </Modal>
        </div>
    );
};

export default ProductData;