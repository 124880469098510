import React, {useEffect, useState, Fragment} from 'react';
import Ajax from "../../../api/Ajax";
import {Button, Modal, Table, Tag, TreeSelect, Form, Row, Col, Input, Select, InputNumber, DatePicker, Switch, message} from "antd";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {getRoleTree, getStoreTree} from "../../../common/array";
import LocalStore from "../../../utils/LocalStore";
import Auth from "../../../common/Auth";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import "./index.less";

/**
 * 从后端接受的用户数据
 */
export interface User {
    id: number,
    name: string,
    roleIds: number[],
    storeIds: number[]
}

/**
 * 根据角色id获取对应的角色名称
 * @param roleId 角色id
 */
const getRoleTreeName = (roleId: number): string => {
    const role = LocalStore.get('role');
    let title = '';
    role.forEach(e => {
        if (e.id === roleId) {
            title = e.name;
            return title;
        }
    })
    return title;
}
/**
 * 根据公司id获取对应的角公司名称
 * @param companyId 公司id
 */
const getCompanyTreeName = (companyId: number): string => {
    const role = LocalStore.get('store');
    let title = '';
    role.forEach(e => {
        if (e.id === companyId) {
            title = e.name;
            return title;
        }
    })
    return title;
}
interface AccountData {
    id: number;
    username: string;
    password: string;
    name: string;
    sex: string;
    birthday: string;
    age: string;
    phone: string;
    post: string;
    status: string;
    seq: number;
    remark: string;
    roleIds: [];
    storeIds: [];
}
const AccountData = props => {
    dayjs.extend(customParseFormat);
    const { Search } = Input;
    const { TextArea } = Input;
    const dateFormat = 'YYYY-MM-DD';
    const [users, setUsers] = useState<User[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('新增账户');
    const [form] = Form.useForm();
    const [currentRecord, setCurrentRecord] = useState<AccountData | null>(null);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [pwdModalVisible, setPwdModalVisible] = useState<boolean>(false);
    const [statusVisible, setStatusVisible] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [switchChecked, setSwitchChecked] = useState(true);
    const canDelete = selectedRowKeys.length > 0;
    const canModifyStatus = selectedRowKeys.length > 0;
    const sexOption = [
        { label: '男', value: '男' },
        { label: '女', value: '女' }
    ];
    // 表设置
    const columns: ColumnsType<User> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            align: 'center',
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
            align: 'center',
        },
        {
            title: '账户',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
        },
        {
            title: '角色',
            key: 'roleIds',
            dataIndex: 'roleIds',
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            width: 230,
            render: roleIds => (
                <>
                    {roleIds.map(roleId => {
                        return (
                            <Tag color='geekblue' key={roleId}>
                                {getRoleTreeName(roleId)}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: '门店',
            key: 'storeIds',
            dataIndex: 'storeIds',
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            width: 280,
            render: storeIds => (
                <>
                    {storeIds.map(storeId => {
                        return (
                            <Tag color='green' key={storeId}>
                                {getCompanyTreeName(storeId)}
                            </Tag>
                        );
                    })}
                </>
            )
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (status) => (
                <Tag color={status === '0' ? 'green' : 'red'}>
                    {status === '0' ? '正常' : '禁用'}
                </Tag>
            ),
        },
        {
            title: '操作',
            fixed: 'right',
            width: 230,
            render: (text, record) => (
                <Fragment>
                    <Auth resourceId={1003}>
                        <Button size='small' type='link' onClick={() => handleEdit(record)}>编辑</Button>
                    </Auth>
                    <Auth resourceId={1002}>
                        <Button
                            size='small'
                            type='link'
                            onClick={() => passwordModal(record)}>密码修改</Button>
                    </Auth>
                    <Auth resourceId={1003}>
                        <Button
                            size='small'
                            type='link'
                            onClick={e => {
                                setSelectedUser(record);
                                setModalVisible(true);
                            }}>权限配置</Button>
                    </Auth>
                </Fragment>
            )
        },
    ];
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({...pagination});
        fetch(pagination);
    };
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/user/page/' + pagination.current).then(body => {
            const users = body.data.records;
            users.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            // 给数据设置好key，好渲染组件
            setUsers(users);
            setPagination({...pagination, total: body.data.total});
            setLoading(false);
        });
    }

    // 当角色树状图更改时
    const roleTreeOnChange = value => {
        const tempUser = {...selectedUser};
        tempUser.roleIds = value;
        setSelectedUser(tempUser);
    }
    // 当公司树状图更改时
    const companyTreeOnChange = value => {
        const tempUser = {...selectedUser};
        tempUser.storeIds = value;
        setSelectedUser(tempUser);
    }

    // 当编辑按钮按下确定按钮
    const editHandleOk = () => {
        setConfirmLoading(true);
        Ajax.post('/user/config', selectedUser).then(body => {
                message.success(body.msg);
                setModalVisible(false);
                fetch(pagination);
            }
        ).finally(() => {
            setConfirmLoading(false)
        });
    }
    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        // eslint-disable-next-line
    }, [])
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增账户');
        setVisible(true);
        form.resetFields();
        setCurrentRecord(null);
    };
    /**
     * @description: 编辑
     * @param {AccountData} record
     * @return {*}
     */
    const handleEdit = (record: AccountData) => {
        setModalTitle('编辑账户');
        setVisible(true);
        form.setFieldsValue({
            username: record.username,
            name: record.name,
            sex: record.sex,
            birthday: dayjs(record.birthday, dateFormat),
            age: record.age,
            phone: record.phone,
            post: record.post,
            seq: record.seq,
            remark: record.remark,
        });
        setCurrentRecord(record);
    };
    /**
         * @description: 新增/修改弹窗-确定事件
         * @return {*}
         */
    const handleOk = async () => {
        const values = await form.validateFields();
        if (values.birthday) {
            values.birthday = values.birthday.format('YYYY-MM-DD');
        }
        if (values.age !== undefined) {
            values.age = String(values.age);
        }
        values.status = values.status ? '0' : '1';
        if (currentRecord) {
            // 编辑
            values.id = currentRecord.id
            Ajax.post('/user/update', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        } else {
            // 新增
            Ajax.post('/user/create', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        }
        setVisible(false);
    };
    /**
     * @description: 新增/修改弹窗-取消事件
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: AccountData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认删除
     * @return {*}
     */    
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/user/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    /**
     * @description: 确认状态修改
     * @return {*}
     */    
    const statusHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const statusValue = switchChecked ? '0' : '1';
            
            const body = await Ajax.post(`/user/status?ids=${params}&status=${statusValue}`);
            if (body.code === 0) {
                message.success(body.msg);
                setStatusVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("状态修改失败");
        } finally {
            setStatusVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    /**
     * @description: 密码修改弹窗
     * @param {*} user
     * @return {*}
     */
    const passwordModal = (user) => {
        setCurrentUser(user);
        setPwdModalVisible(true);
        setNewPassword('');
    };
    /**
     * @description: 密码修改弹窗-确定事件
     * @return {*}
     */
    const passwordOk = async () => {
        if (!newPassword) {
            message.error('密码不能为空');
            return;
        }
        const data = currentUser
        data.password= newPassword
        try {
            Ajax.post('/user/pwd', data).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setPwdModalVisible(false);
                }
            });
        } catch (error) {
            message.error('密码修改失败，请重试');
        } finally {
            // 处理完成后关闭模态框
            setPwdModalVisible(false);
            setCurrentUser(null);
            setNewPassword('');
        }
    };
    /**
     * @description: 密码修改弹窗-取消事件
     * @return {*}
     */
    const passwordCancel = () => {
        setPwdModalVisible(false);
        setCurrentUser(null);
        setNewPassword('');
    };
    const statusCancel = () => {
        setStatusVisible(false);
        setCurrentUser(null);
        setSwitchChecked(true);
    };
    const handleSwitchChange = (checked) => {
        setSwitchChecked(checked);
    };
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/user/page/' + pagination.current, { name: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setUsers(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <h1>账户管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入姓名" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Auth resourceId={1001}>
                    <Button type="primary" className="buttonSpace" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
                </Auth>
                <Auth resourceId={1001}>
                    <Button type="primary" className="buttonSpace" disabled={!canModifyStatus} onClick={() => { setSwitchChecked(true); setStatusVisible(true); }}>状态修改</Button>
                </Auth>
                <Auth resourceId={1001}>
                    <Button className="buttonSpace" type='primary' onClick={handleAdd}>新增</Button>
                </Auth>
            </div>
            <Table
                dataSource={users}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="权限编辑"
                visible={modalVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={editHandleOk}
                onCancel={() => {setModalVisible(false)}}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>角色：</span>
                    <TreeSelect
                        treeData={getRoleTree()}
                        value={selectedUser === null ? [] : selectedUser.roleIds}
                        style={{width: '80%'}}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        treeCheckable={true}
                        onChange={roleTreeOnChange}
                        treeDefaultExpandAll={true}
                        placeholder='请选择角色'
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center',marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}>门店：</span>
                    <TreeSelect
                        treeData={getStoreTree()}
                        value={selectedUser === null ? [] : selectedUser.storeIds}
                        style={{width: '80%'}}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeCheckable={true}
                        onChange={companyTreeOnChange}
                        treeDefaultExpandAll={true}
                        placeholder='请选择门店'
                    />
                </div>
            </Modal>
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除账户名【{selectedRowKeys.map(key => {
                        const user = users.find(user => user.id === key);
                        return user ? user.name : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal
                title={`${currentUser ? currentUser.name : ''}-密码修改`}
                visible={pwdModalVisible}
                cancelText='取消'
                okText='确认'
                onOk={passwordOk}
                onCancel={passwordCancel}
            >   
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>登录密码：</span>
                    <Input.Password
                        placeholder="输入新密码"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        style={{ width: '80%' }}
                    />
                </div>
            </Modal>
            <Modal
                title="状态修改"
                visible={statusVisible}
                cancelText='取消'
                okText='确认'
                onOk={statusHandleOk}
                onCancel={statusCancel}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>账户状态：</span>
                    <Switch 
                        checked={switchChecked} 
                        onChange={handleSwitchChange} 
                        checkedChildren="正常" 
                        unCheckedChildren="禁用" 
                    />
                </div>
            </Modal>
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }}>
                    <Row gutter={24}>
                        {currentRecord && (
                            <Col span={12}>
                                <Form.Item name="seq" label="序号" rules={[{ required: true, message: '请输入序号' }]}>
                                    <InputNumber step={1} placeholder="请输入序号" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item name="username" label="账户" rules={[{ required: true, message: '请输入账户' }]} >
                                <Input placeholder="请输入账户" disabled={!!currentRecord} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="name" label="姓名" rules={[{ required: true, message: '请输入登录姓名' }]} >
                                <Input placeholder="请输入登录姓名" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="sex" label="性别" rules={[{ required: true, message: '请选择性别' }]}>
                                <Select placeholder="请选择性别" allowClear options={sexOption}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="birthday" label="生日" rules={[{ required: true, message: '请输入生日日期' }]} >
                                <DatePicker format="YYYY-MM-DD" placeholder="请输入生日日期"  style={{width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="age" label="年龄" rules={[{ required: true, message: '请输入年龄' }]}>
                                <InputNumber step={1} precision={0} placeholder="请输入年龄" style={{width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="phone" label="电话" rules={[{ required: true, message: '请输入电话' }, { pattern: /^\d{11}$/, message: '电话格式不正确，应为11位数字' }]} >
                                <Input placeholder="请输入电话" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="post" label="职位" rules={[{ required: false, message: '请输入职位' }]}>
                                <Input placeholder="请输入职位" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="remark" label="备注" rules={[{ required: false, message: '请输入备注信息' }]}>
                                <TextArea rows={4} placeholder="请输入备注信息"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default AccountData;
