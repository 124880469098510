import React, { useEffect, useState, Fragment } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Table, message, Button, Row, Col, Input, Modal, Form, InputNumber, Select } from "antd";
import Ajax from "../../../api/Ajax";
import "./index.less";
import LocalStore from "../../../utils/LocalStore";

interface ServiceTypeData {
    id: number;
    seq: number;
    name: string;
    serviceId: number;
    serviceName: string;
    remark: string;
    details: string;
    createTime: Date;
}
interface Service {
    id: number;
    name: string;
}
interface SelectOption {
    label: string;
    value: number | string;
}

const ServiceTypeData = props => {
    const { Search } = Input;
    const { TextArea } = Input;
    const [data, setData] = useState<ServiceTypeData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<string>('新增类型');
    const [currentRecord, setCurrentRecord] = useState<ServiceTypeData | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [serviceNameOption, setServiceNameOption] = useState<SelectOption[]>([]);
    const canDelete = selectedRowKeys.length > 0;
    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<ServiceTypeData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '所属服务',
            dataIndex: 'serviceName',
            key: 'serviceName',
            align: 'center',
        },
        {
            title: '类型名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '类型描述',
            key: 'details',
            dataIndex: 'details',
            align: 'center',
        },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            align: 'center',
        },
        {
            title: '操作',
            fixed: 'right',
            width: 180,
            render: (text, record) => (
                <Fragment>
                    <Button size='small' type='link' onClick={() => handleEdit(record)}>编辑</Button>
                </Fragment>
            ),
        },
    ];
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetch(pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        // eslint-disable-next-line
    }, [])
    const fetchServices = async () => {
        try {
            const response = await Ajax.get('/store-service/list');
            const services = response.data;
            const options = services.map((service: Service) => ({
                label: service.name,
                value: service.id,
            }));
            setServiceNameOption(options);
        } catch (error) {
            message.error('无法获取服务列表，请稍后重试');
        }
    };
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/store-service-type/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增类型');
        fetchServices();
        setVisible(true);
        form.resetFields();
        setCurrentRecord(null);
    };
    /**
     * @description: 编辑
     * @param {ServiceTypeData} record
     * @return {*}
     */
    const handleEdit = (record: ServiceTypeData) => {
        setModalTitle('编辑类型');
        fetchServices();
        setVisible(true);
        form.setFieldsValue({
            seq: record.seq,
            name: record.name,
            serviceId: record.serviceId,
            serviceName: record.serviceName,
            details: record.details,
            remark: record.remark,
        });
        setCurrentRecord(record);
    };
    /**
     * @description: 确定
     * @return {*}
     */
    const handleOk = async () => {
        const values = await form.validateFields();
        values.status = values.status ? '0' : '1';
        // 如果是编辑，则进行编辑 API 请求
        if (currentRecord) {
                // 编辑
                values.id = currentRecord.id
                Ajax.post('/store-service-type/update', values).then(body => {
                    if (body.code === 0) {
                        message.success(body.data);
                        setVisible(false);
                        fetch(pagination);
                    }
                });
            } else {
                // 新增
                values.serviceName = getServiceName(values.serviceId)
                Ajax.post('/store-service-type/create', values).then(body => {
                    if (body.code === 0) {
                        message.success(body.data);
                        setVisible(false);
                        fetch(pagination);
                    }
                });
            }
        setVisible(false);
    };
    /**
     * @description: 取消
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: ServiceTypeData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认删除
     * @return {*}
     */    
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/store-service-type/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const getServiceName = (resourceId: number): string => {
        const resource = LocalStore.get('service');
        let title = '';
        resource.forEach(e => {
            if (e.id === resourceId) {
                title = e.name;
                return title;
            }
        })
        return title;
    }
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/store-service-type/page/' + pagination.current, { name: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <h1>类型管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入类型名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button type="primary" className="buttonSpace" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
                <Button className="buttonSpace" type='primary' onClick={handleAdd}>新增</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除服务名称【{selectedRowKeys.map(key => {
                        const user = data.find(user => user.id === key);
                        return user ? user.name : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }}>
                    <Row gutter={24}>
                        {currentRecord && (
                            <Col span={12}>
                                <Form.Item name="seq" label="序号" rules={[{ required: false, message: '请输入序号' }]}>
                                    <InputNumber step={1} placeholder="请输入序号" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item name="serviceId" label="所属服务" rules={[{ required: true, message: '请选择所属服务' }]}>
                                <Select placeholder="请选择所属服务" allowClear options={serviceNameOption} disabled={!!currentRecord}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="name" label="类型名称" rules={[{ required: true, message: '请输入类型名称' }]} >
                                <Input placeholder="请输入类型名称" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="details" label="类型描述" rules={[{ required: true, message: '请输入类型描述' }]}>
                                <TextArea rows={4} placeholder="请输入类型描述"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="remark" label="备注" rules={[{ required: false, message: '请输入备注信息' }]}>
                                <TextArea rows={4} placeholder="请输入备注信息"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default ServiceTypeData;