import React from 'react';
import LoginUtil from "../../utils/LoginUtil";
import Ajax from "../../api/Ajax";
import {Dropdown, Menu, message} from "antd";
import './index.less';
import {
    DownOutlined
} from '@ant-design/icons';

/**
 * @description: 退出登录
 * @return {*}
 */
const onLogout = async () => {
    try {
        const res = await Ajax.get('/logout');
        if (res.code === 0) {
            message.success(res.data);
            LoginUtil.removeLoginState();
            window.location.href = '/';
        }
    } catch (error) {
        console.error("Logout failed", error);
        // 可以在这里处理错误，比如显示提示
    }
};

const menu = (
    <Menu>
        <Menu.Item onClick={onLogout}>退出登录</Menu.Item>
    </Menu>
);

/**
 * 顶部右边菜单栏
 */
const TopRightMenu = props => {
    return (
        <Dropdown overlay={menu}>
            <span>
                {LoginUtil.getUsername()} <DownOutlined/>
            </span>
        </Dropdown>
    );
};

export default TopRightMenu;
