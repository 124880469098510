import React, { useEffect, useState, Fragment } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import moment from 'moment';
import { Table, message, Button, Row, Col, Input, Modal, Form, InputNumber, Tag, Switch, Select, TimePicker } from "antd";
import LocalStore from "../../utils/LocalStore";
import Auth from "../../common/Auth";
import Ajax from "../../api/Ajax";
import "./index.less";
import { log } from 'console';

interface StoreData {
    id: number;
    seq: number;
    name: string;
    address: string;
    tel: number;
    remark: string;
    createTime: Date;
    status: string;
    serviceIds: any[];
    hotProductIds: any[];
    serviceTypeIds: any[];
    productIds: any[];
    roleIds: number[];
    storeIds: number[];
    workTime: string;
    longitude: number;
    latitude: number;
    employeeNum: number;
}

interface Service {
    id: number;
    name: string;
}
interface SelectOption {
    label: string;
    value: number | string;
}

const StoreData = props => {
    const { Search } = Input;
    const { TextArea } = Input;
    const [data, setData] = useState<StoreData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [statusVisible, setStatusVisible] = useState<boolean>(false);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<string>('新增门店');
    const [currentRecord, setCurrentRecord] = useState<StoreData | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<StoreData>(null);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [serviceNameOption, setServiceNameOption] = useState<SelectOption[]>([]);
    const [serviceTypeOption, setServiceTypeOption] = useState<SelectOption[]>([]);
    const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>([]);
    const [selectedServiceTypeIds, setServiceTypeIds] = useState<number[]>([]);
    const [selectedProductIds, setProductIds] = useState<number[]>([]);
    const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
    const [selectedHotProductIds, setSelectedHotProductIds] = useState<number[]>([]);
    const canDelete = selectedRowKeys.length > 0;
    const canModifyStatus = selectedRowKeys.length > 0;
    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<StoreData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '门店名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '门店电话',
            key: 'tel',
            dataIndex: 'tel',
            align: 'center',
        },
        {
            title: '门店地址',
            key: 'address',
            dataIndex: 'address',
            align: 'center',
        },
        {
            title: '运营时间',
            key: 'workTime',
            dataIndex: 'workTime',
            align: 'center',
        },
        {
            title: '雇员人数',
            key: 'employeeNum',
            dataIndex: 'employeeNum',
            align: 'center',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (status) => (
                <Tag color={status === '1' ? 'green' : 'red'}>
                    {status === '1' ? '运营' : '关闭'}
                </Tag>
                // 关闭是0，运营是1
            )
        },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            align: 'center',
        },
        {
            title: '操作',
            fixed: 'right',
            width: 160,
            render: (text, record) => (
                <Fragment>
                    <Button size='small' type='link' onClick={() => handleEdit(record)}>编辑</Button>
                    <Auth resourceId={3003}>
                        <Button size='small' type='link' onClick={e => { setSelectedUser(record);setModalVisible(true);}}>产品配置</Button>
                    </Auth>
                    <Button size='small' type='link' onClick={() => syncProducts(record.id)}>同步产品</Button>
                </Fragment>
            ),
        },
    ];
    /**
     * @description: 同步产品
     * @param {number} storeId
     * @return {*}
     */    
    const syncProducts = async (storeId: number) => {
        setConfirmLoading(true);
        try {
            const response = await Ajax.post(`/store/config-all-product?storeId=${storeId}`);
            if (response.code === 0) {
                message.success(response.msg);
                fetch(pagination);
            } else {
                message.error(response.msg);
            }
        } catch (error) {
            message.error("同步产品失败，请重试");
        } finally {
            setConfirmLoading(false);
        }
    };
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetch(pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);  // 拉取分页数据
        if (modalVisible && selectedUser) {
            fetchServices();
            setSelectedServiceIds(selectedUser.serviceIds || []);
            setServiceTypeIds(selectedUser.serviceTypeIds || []);
            setProductIds(selectedUser.productIds || []);
            if (!selectedUser.serviceIds || selectedUser.serviceIds.length === 0) {
                setServiceTypeOption([]); // 清空门店类型
                setProductOptions([]); // 清空门店产品
                setSelectedHotProductIds([]); // 清空门店产品
            } else {
                fetchServiceTypes(selectedUser.serviceIds);
            }
            if (selectedUser.serviceTypeIds && selectedUser.serviceTypeIds.length > 0) {
                fetchProducts(selectedUser.serviceTypeIds);
            }
            if (selectedUser.hotProductIds && selectedUser.hotProductIds.length > 0) {
                setSelectedHotProductIds(selectedUser.hotProductIds || []);
            }
        }
        // eslint-disable-next-line
    }, [modalVisible, selectedUser]);
    const fetchServices = async () => {
        try {
            const response = await Ajax.get('/store-service/list');
            const services = response.data;
            const options = services.map((service: Service) => ({
                label: service.name,
                value: service.id,
            }));
            setServiceNameOption(options);
        } catch (error) {
            message.error('无法获取服务列表，请稍后重试');
        }
    };
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/store/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/store/page/' + pagination.current, { name: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增门店');
        setVisible(true);
        form.resetFields();
        setCurrentRecord(null);
    };
    /**
     * @description: 编辑
     * @param {StoreData} record
     * @return {*}
     */
    const handleEdit = (record: StoreData) => {
        setModalTitle('编辑门店');
        setVisible(true);
        form.setFieldsValue({
            seq: record.seq,
            name: record.name,
            tel: record.tel,
            address: record.address,
            longitude: record.longitude,
            latitude: record.latitude,
            employeeNum: record.employeeNum,
            remark: record.remark,
            workTime: record.workTime,
        });
        setCurrentRecord(record);
    };
    /**
     * @description: 确定
     * @return {*}
     */
    const handleOk = async () => {
        const values = await form.validateFields();
        values.status = values.status ? '0' : '1';
        // 如果是编辑，则进行编辑 API 请求
        if (currentRecord) {
                // 编辑
                values.id = currentRecord.id
                Ajax.post('/store/update', values).then(body => {
                    if (body.code === 0) {
                        message.success(body.data);
                        setVisible(false);
                        fetch(pagination);
                    }
                });
            } else {
                // 新增
                Ajax.post('/store/create', values).then(body => {
                    if (body.code === 0) {
                        message.success(body.data);
                        setVisible(false);
                        fetch(pagination);
                    }
                });
            }
        setVisible(false);
    };
    /**
     * @description: 取消
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: StoreData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认删除
     * @return {*}
     */    
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/store/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    /**
     * @description: 确认状态修改
     * @return {*}
     */    
    const statusHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const statusValue = switchChecked ? '1' : '0';
            const body = await Ajax.post(`/store/status?ids=${params}&status=${statusValue}`);
            if (body.code === 0) {
                message.success(body.msg);
                setStatusVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("状态修改失败");
        } finally {
            setStatusVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const statusCancel = () => {
        setStatusVisible(false);
        setSwitchChecked(false);
    };
    const handleSwitchChange = (checked) => {
        setSwitchChecked(checked);
    };
    // 当产品配置编辑按钮按下确定按钮
    const editHandleOk = () => {
        setConfirmLoading(true);
        const values = {
            ...selectedUser,
            serviceIds: selectedServiceIds, 
            serviceTypeIds: selectedServiceTypeIds, 
            productIds: selectedProductIds, 
            hotProductIds: selectedHotProductIds
        };
        Ajax.post('/store/config', values).then(body => {
                message.success(body.msg);
                setModalVisible(false);
                fetch(pagination);
            }
        ).finally(() => {
            setConfirmLoading(false)
        });
    };
    const editCancel = () => {
        setModalVisible(false);
    };
    /**
     * @description: 门店服务选择事件
     * @param {*} selectedServiceIds
     * @return {*}
     */    
    const serviceChange = (selectedServiceIds) => {
        setSelectedServiceIds(selectedServiceIds);
        fetchServiceTypes(selectedServiceIds)
    };
    /**
     * @description: 门店类型选择事件
     * @param {*} selectedServiceTypeIds
     * @return {*}
     */    
    const serviceTypeChange = (selectedServiceTypeIds) => {
        setServiceTypeIds(selectedServiceTypeIds);
        fetchProducts(selectedServiceTypeIds)
    };
    /**
     * @description: 产品选择事件
     * @param {*} selectedProductIds
     * @return {*}
     */    
    const productChange = (selectedProductIds) => {
        setProductIds(selectedProductIds);
    };
    const hotProductChange = (selectedHotProductIds) => {
        setSelectedHotProductIds(selectedHotProductIds);
    };
    const fetchServiceTypes = async (serviceIds) => {
        try {
            const response = await Ajax.get(`/store-service-type/list-by-serviceids?ids=${serviceIds}`);
            const options = response.data.map((serviceType: Service) => ({
                label: serviceType.name,
                value: serviceType.id,
            }));
            setServiceTypeOption(options);
        } catch (error) {
            console.error('Error fetching store types:', error);
            return [];
        }
    };
    const fetchProducts = async (serviceIds) => {
        try {
            const response = await Ajax.get(`/product/list-by-servicetypeids?ids=${serviceIds}`);
            const options = response.data.map((serviceType: Service) => ({
                label: serviceType.name,
                value: serviceType.id,
            }));
            setProductOptions(options);
        } catch (error) {
            console.error('Error fetching store types:', error);
            return [];
        }
    };
    return (
        <div>
            <h1>门店管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入门店名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button type="primary" className="buttonSpace" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
                <Button type="primary" className="buttonSpace" disabled={!canModifyStatus} onClick={() => { setSwitchChecked(false); setStatusVisible(true); }}>状态修改</Button>
                <Button className="buttonSpace" type='primary' onClick={handleAdd}>新增</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除门店名称【{selectedRowKeys.map(key => {
                        const user = data.find(user => user.id === key);
                        return user ? user.name : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal
                title="状态修改"
                visible={statusVisible}
                cancelText='取消'
                okText='确认'
                onOk={statusHandleOk}
                onCancel={statusCancel}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>门店状态：</span>
                    <Switch 
                        checked={switchChecked} 
                        onChange={handleSwitchChange} 
                        checkedChildren="运营" 
                        unCheckedChildren="关闭" 
                    />
                </div>
            </Modal>
            <Modal
                title="产品配置"
                visible={modalVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={editHandleOk}
                onCancel={editCancel}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>门店服务：</span>
                    <Select mode="multiple" allowClear value={selectedServiceIds} options={serviceNameOption} style={{width: '80%' }} onChange={serviceChange}  placeholder="请选择门店服务"></Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center',marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}>门店类型：</span>
                    <Select mode="multiple" allowClear value={selectedServiceTypeIds} options={serviceTypeOption} style={{width: '80%' }} onChange={serviceTypeChange} placeholder="请选择门店类型"></Select>

                </div>
                <div style={{ display: 'flex', alignItems: 'center',marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}>门店产品：</span>
                    <Select mode="multiple" allowClear value={selectedProductIds} options={productOptions} style={{width: '80%' }} onChange={productChange} placeholder="请选择所门店产品" ></Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center',marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}>热门产品：</span>
                    <Select mode="multiple" allowClear value={selectedHotProductIds} options={productOptions} style={{width: '80%' }} onChange={hotProductChange} placeholder="请选择热门产品" ></Select>
                </div>
            </Modal>
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }} initialValues={{longitude: 0, latitude: 0}}>
                    <Row gutter={24}>
                        {currentRecord && (
                            <Col span={12}>
                                <Form.Item name="seq" label="序号" rules={[{ required: true, message: '请输入序号' }]}>
                                    <InputNumber step={1} placeholder="请输入序号" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item name="name" label="门店名称" rules={[{ required: true, message: '请输入门店名称' }]} >
                                <Input placeholder="请输入门店名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="tel" label="门店电话" rules={[{ required: true, message: '请输入门店电话' }]} >
                                <Input placeholder="请输入门店电话" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="address" label="门店地址" rules={[{ required: true, message: '请输入门店地址' }]} >
                                <Input placeholder="请输入门店地址" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="workTime" label="运营时间" rules={[{ required: true, message: '请输入运营时间' }]} >
                                <Input placeholder="请输入运营时间" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="longitude" label="经度" rules={[{ required: true, message: '请输入经度' }, { type: 'number', message: '必须为数字类型' }]} >
                                <InputNumber step={0.000001} precision={6} placeholder="请输入经度" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="latitude" label="纬度" rules={[{ required: true, message: '请输入纬度' }, { type: 'number', message: '必须为数字类型' }]} >
                                <InputNumber step={0.000001} precision={6} placeholder="请输入纬度" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="employeeNum" label="雇员人数" rules={[{ required: true, message: '请输入雇员人数' }]}>
                                <InputNumber step={1} placeholder="请输入雇员人数" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="remark" label="备注" rules={[{ required: false, message: '请输入备注信息' }]}>
                                <TextArea rows={4} placeholder="请输入备注信息"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default StoreData;