import React, { useEffect, useState, Fragment } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Table, message, Button, Row, Col, Input, Modal, Form, InputNumber, Select, Switch, Tag, DatePicker, ConfigProvider } from "antd";
import Ajax from "../../api/Ajax";
import "./index.less";
import moment from 'moment';

interface CouponData {
    id: number;
    seq: number;
    status: string;
    name: string;
    activityCode: string;
    activityType: string;
    couponAttr: string;
    details: string;
    discount: number;
    startTime: string;
    endTime: string;
    createTime: Date;
}
interface Service {
    id: number;
    name: string;
}
interface ServiceType {
    id: number;
    name: string;
}
interface SelectOption {
    label: string;
    value: number | string;
}

const CouponData = props => {
    const { Search } = Input;
    const { RangePicker } = DatePicker; 
    const { TextArea } = Input;
    const [data, setData] = useState<CouponData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [statusVisible, setStatusVisible] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<string>('新增banner');
    const [currentRecord, setCurrentRecord] = useState<CouponData | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [switchChecked, setSwitchChecked] = useState(false);
    const canDelete = selectedRowKeys.length > 0;
    const canModifyStatus = selectedRowKeys.length > 0;
    const [activityTypeLabel, setActivityTypeLabel] = useState<string>('');
    const [couponAttrLabel, setCouponAttrLabel] = useState<string>('');
    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<CouponData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '活动编号',
            dataIndex: 'activityCode',
            key: 'activityCode',
            align: 'center',
        },
        {
            title: '活动分类',
            dataIndex: 'activityType',
            key: 'activityType',
            align: 'center',
            render: (activityType) => activityTypeOptions[activityType] || '',
        },
        {
            title: '优惠券属性',
            dataIndex: 'couponAttr',
            key: 'couponAttr',
            align: 'center',
            render: (couponAttr) => couponAttrOptions[couponAttr] || '',
        },
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '折扣或金额',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
        },
        {
            title: '优惠券描述',
            key: 'details',
            dataIndex: 'details',
            align: 'center',
        },
        {
            title: '活动开始时间',
            key: 'startTime',
            dataIndex: 'startTime',
            align: 'center',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '活动结束时间',
            key: 'endTime',
            dataIndex: 'endTime',
            align: 'center',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '活动状态',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (status) => (
                <Tag color={status === '1' ? 'green' : 'red'}>
                    {status === '1' ? '启用' : '关闭'}
                </Tag>
            )
        },
        {
            title: '操作',
            fixed: 'right',
            width: 180,
            render: (text, record) => (
                <Fragment>
                    <Button size='small' type='link' onClick={() => handleEdit(record)}>编辑</Button>
                </Fragment>
            ),
        },
    ];
    const activityTypeOptions: { [key: number]: string } = {
        1: '分享活动',
        2: '新用户活动',
        3: '其他活动'
    };
    const couponAttrOptions: { [key: number]: string } = {
        1: '折扣',
        2: '金额'
    };

    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetch(pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        // eslint-disable-next-line
    }, [])
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/coupon/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增优惠券');
        setVisible(true);
        form.resetFields();
        setCurrentRecord(null);
    };
    /**
     * @description: 编辑
     * @param {CouponData} record
     * @return {*}
     */
    const handleEdit = (record: CouponData) => {
        setModalTitle('编辑优惠券');
        setVisible(true);
        setCurrentRecord(record);
        form.setFieldsValue({
            seq: record.seq,
            name: record.name,
            activityCode: record.activityCode,
            couponAttr: record.couponAttr,
            activityType: record.activityType,
            startTime: moment(record.startTime),  // 使用 moment.js 进行时间格式化
            endTime: moment(record.endTime),      // 使用 moment.js 进行时间格式化
            discount: record.discount,
            details: record.details
        });
        setActivityTypeLabel(activityTypeOptions[record.activityType]);
        setCouponAttrLabel(activityTypeOptions[record.couponAttr]);
    };
    /**
     * @description: 确定
     * @return {*}
     */
    const handleOk = async () => {
        const values = await form.validateFields();
        values.status = values.status ? '0' : '1';
        // 如果是编辑，则进行编辑 API 请求
        if (currentRecord) {
            // 编辑
            values.id = currentRecord.id
            Ajax.post('/coupon/update', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        } else {
            // 新增
            Ajax.post('/coupon/create', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        }
        setVisible(false);
    };
    /**
     * @description: 取消
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: CouponData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认删除
     * @return {*}
     */    
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/coupon/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    /**
     * @description: 确认状态修改
     * @return {*}
     */    
    const statusHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const statusValue = switchChecked ? '1' : '0';
            const body = await Ajax.post(`/coupon/status?ids=${params}&status=${statusValue}`);
            if (body.code === 0) {
                message.success(body.msg);
                setStatusVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("状态修改失败");
        } finally {
            setStatusVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const statusCancel = () => {
        setStatusVisible(false);
        setSwitchChecked(true);
    };
    const handleSwitchChange = (checked) => {
        setSwitchChecked(checked);
    };
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/coupon/page/' + pagination.current, { name: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <h1>优惠券管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入优惠券名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button type="primary" className="buttonSpace" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
                <Button type="primary" className="buttonSpace" disabled={!canModifyStatus} onClick={() => { setSwitchChecked(true); setStatusVisible(true); }}>状态修改</Button>
                <Button className="buttonSpace" type='primary' onClick={handleAdd}>新增</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除优惠券名称【{selectedRowKeys.map(key => {
                        const user = data.find(user => user.id === key);
                        return user ? user.name : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal
                title="状态修改"
                visible={statusVisible}
                cancelText='取消'
                okText='确认'
                onOk={statusHandleOk}
                onCancel={statusCancel}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>活动状态：</span>
                    <Switch 
                        checked={switchChecked} 
                        onChange={handleSwitchChange} 
                        checkedChildren="启用" 
                        unCheckedChildren="关闭" 
                    />
                </div>
            </Modal>
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }} initialValues={{price: 0, discount: 0}}>
                    <Row gutter={24}>
                        {currentRecord && (
                            <Col span={12}>
                                <Form.Item name="seq" label="序号" rules={[{ required: false, message: '请输入序号' }]}>
                                    <InputNumber step={1} placeholder="请输入序号" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item name="activityCode" label="活动编号" rules={[{ required: true, message: '请输入活动编号' }]} >
                                <Input placeholder="请输入活动编号"  disabled={!!currentRecord}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="activityType" label="活动分类" rules={[{ required: true, message: '请选择活动分类' }]}>
                                <Select 
                                    placeholder="请选择活动分类"
                                    allowClear 
                                    onChange={value => setActivityTypeLabel(activityTypeOptions[value])}
                                    disabled={!!currentRecord}
                                >
                                    {Object.entries(activityTypeOptions).map(([key, label]) => (
                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="couponAttr" label="优惠券属性" rules={[{ required: true, message: '请选择优惠券属性' }]}>
                                <Select 
                                    placeholder="请选择优惠券属性"
                                    allowClear 
                                    onChange={value => setCouponAttrLabel(couponAttrOptions[value])}
                                >
                                    {Object.entries(couponAttrOptions).map(([key, label]) => (
                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="name" label="优惠券名称" rules={[{ required: true, message: '请输入名称' }]} >
                                <Input placeholder="请输入名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="discount" label="折扣或金额" rules={[{ required: true, message: '请输入折扣或金额' }]}>
                                <InputNumber step={0.01} precision={2} placeholder="请输入折扣或金额" style={{width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="details" label="优惠券描述" rules={[{ required: false, message: '请输入优惠券描述' }]}>
                                <TextArea rows={4} placeholder="请输入优惠券描述"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="startTime" label="活动开始时间" rules={[{ required: true, message: '请选择活动开始时间' }]}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="endTime" label="活动结束时间" rules={[{ required: true, message: '请选择活动结束时间' }]}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default CouponData;