import React, { useEffect, useState, Fragment } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Table, Popconfirm, Button, Row, Col, Input, Modal, Form, Select, Tag } from "antd";
import Ajax from "../../api/Ajax";
import "./index.less";

interface StoreData {
    id: number;
    customerName: string;
    customerPhone: string;
    price: number;
    createTime: Date;
    companyId: number;
    companyName: string;
    hospitalLevel: number;
    hospitalName: string;
}

const StoreData = props => {
    const { Search } = Input;
    const { TextArea } = Input;
    const [data, setData] = useState<StoreData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<string>('新增医院');
    const [currentRecord, setCurrentRecord] = useState<StoreData | null>(null);

    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<StoreData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '代码值',
            dataIndex: 'customerName',
            key: 'customerName',
            align: 'center',
        },
        {
            title: '代码项名称',
            key: 'customerPhone',
            dataIndex: 'customerPhone',
            align: 'center',
        },
        {
            title: '层次码',
            key: 'price',
            dataIndex: 'price',
            align: 'center',
        },
        {
            title: '拼音',
            key: 'isBasic',
            dataIndex: 'isBasic',
            align: 'center',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (status = '禁用') => (
                <Tag color={status === '启用' ? 'green' : 'red'}>
                    {status}
                </Tag>
            ),
        },
        {
            title: '用户编码',
            key: 'length',
            dataIndex: 'length',
            align: 'center',
        },
        {
            title: '比较值',
            key: 'companyName',
            dataIndex: 'companyName',
            align: 'center',
        },
        {
            title: '操作',
            fixed: 'right',
            render: (text, record) => (
                <Fragment>
                    <Button size='small' type='link' onClick={() => handleEdit(record)}>编辑</Button>
                    <Popconfirm
                        title="确定删除？"
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                    >
                        <Button size='small' type='link'>删除</Button>
                    </Popconfirm>
                </Fragment>
            ),
        },
    ];
    /**
     * @description: 医院等级
     * @return {*}
     */    
    const hospitalLevelOption = [
        { label: '一级', value: 0 },
        { label: '二级', value: 1 },
        { label: '三级', value: 2 }
    ];
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetch(pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        // eslint-disable-next-line
    }, [])

    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.get('/data/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = (value: string) => console.log(value);
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: StoreData[]) => {
            // 待补充处理选中行逻辑
        },
        getCheckboxProps: (record: StoreData) => ({
            id: String(record.id), 
        }),
        type: "checkbox" as "checkbox",
    };
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增医院');
        setVisible(true);
        form.resetFields();
        setCurrentRecord(null);
    };
    /**
     * @description: 编辑
     * @param {StoreData} record
     * @return {*}
     */
    const handleEdit = (record: StoreData) => {
        setModalTitle('编辑医院');
        setVisible(true);
        form.setFieldsValue({
            hospitalName: record.customerName,
        });
        setCurrentRecord(record);
    };
    /**
     * @description: 确定
     * @return {*}
     */
    const handleOk = async () => {
        const values = await form.validateFields();
        console.log('Received values:', values);
        // 如果是编辑，则进行编辑 API 请求
        if (currentRecord) {
            // Ajax.post(`/data/edit/${currentRecord.id}`, values).then(response => {
            //     setVisible(false);
            //     fetch(pagination);
            // });
        } else {
            // 否则，进行新增 API 请求
            // Ajax.post('/data/add', values).then(response => {
            //     setVisible(false);
            //     fetch(pagination);
            // });
        }
        setVisible(false);
    };
    /**
     * @description: 取消
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    return (
        <div>
            <div className="searchaArea">
                <Search placeholder="请输入代码名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button className="buttonSpace" type='primary' href='/#/store'>返回</Button>
                <Button className="buttonSpace" type='primary' onClick={handleAdd}>新增</Button>
                <Button className="buttonSpace" type='primary'>启用</Button>
                <Button type='primary'>禁用</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
            />
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="hospitalName" label="医院名称" rules={[{ required: true, message: '请输入医院名称' }]} >
                                <Input placeholder="请输入医院名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="hospitalLevel" label="医院等级" rules={[{ required: true, message: '请选择医院等级' }]}>
                                <Select placeholder="请选择医院等级" allowClear options={hospitalLevelOption}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="hospitalLevel" label="医院类型" rules={[{ required: true, message: '请选择医院类型' }]}>
                                <Select placeholder="请选择医院等级" allowClear options={hospitalLevelOption}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="hospitalLevel" label="医院类别" rules={[{ required: true, message: '请选择医院类别' }]}>
                                <Select placeholder="请选择医院等级" allowClear options={hospitalLevelOption}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="hospitalLevel" label="是否医保定点" rules={[{ required: true, message: '请选择是否医保定点' }]}>
                                <Select placeholder="请选择医院等级" allowClear options={hospitalLevelOption}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="hospitalName" label="医院照片" rules={[{ required: true, message: '请输入医院照片' }]}>
                                <Input placeholder="请输入医院名称" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="hospitalName1" label="医院地址" rules={[{ required: true, message: '请输入医院地址' }]}>
                                <Input placeholder="请输入医院地址" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="hospitalName1" label="医院描述" rules={[{ required: true, message: '请输入医院描述' }]}>
                                <TextArea rows={4} placeholder="请输入医院描述" maxLength={6} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default StoreData;