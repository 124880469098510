import React, { useEffect, useState, Fragment } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Table, message, Button, Row, Col, Input, Modal, Form, InputNumber, Upload, Image, UploadProps } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import Ajax from "../../../api/Ajax";
import "./index.less";

interface ServiceData {
    id: number;
    seq: number;
    name: string;
    remark: string;
    details: string;
    fileName: string;
    createTime: Date;
}

const ServiceData = props => {
    const { Search } = Input;
    const { TextArea } = Input;
    const [data, setData] = useState<ServiceData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<string>('新增产品');
    const [currentRecord, setCurrentRecord] = useState<ServiceData | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const canDelete = selectedRowKeys.length > 0;
    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<ServiceData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '服务名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '服务描述',
            key: 'details',
            dataIndex: 'details',
            align: 'center',
        },
        {
            title: '服务icon',
            dataIndex: 'fileName',
            key: 'fileName',
            align: 'center',
        },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            align: 'center',
        },
        {
            title: '操作',
            fixed: 'right',
            width: 180,
            render: (text, record) => (
                <Fragment>
                    <Button size='small' type='link' onClick={() => handleEdit(record)}>编辑</Button>
                </Fragment>
            ),
        },
    ];
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetch(pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetch(pagination);
        // eslint-disable-next-line
    }, [])
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.post('/store-service/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 新增
     * @return {*}
     */    
    const handleAdd = () => {
        setModalTitle('新增服务');
        setVisible(true);
        setIsAdding(true);
        form.resetFields();
        setFileList([]);
        setCurrentRecord(null);
    };
    /**
     * @description: 编辑
     * @param {ServiceData} record
     * @return {*}
     */
    const handleEdit = (record: ServiceData) => {
        setModalTitle('编辑服务');
        setVisible(true);
        setIsAdding(false);
        form.setFieldsValue({
            seq: record.seq,
            name: record.name,
            details: record.details,
            remark: record.remark,
        });
        setCurrentRecord(record);
        // 图片回显
        if (record.fileName) {
            const fileUrl = `https://catmorning.com/service/prod/${record.fileName}`; // 生产环境
            // const fileUrl = `http://1.94.124.102:8088//service/public/${record.fileName}`;  // 开发环境
            const newFileList = [{
                uid: record.id, 
                name: record.fileName,
                status: 'done',
                url: fileUrl,
            }];
            setFileList(newFileList);
        } else {
            setFileList([]);
        }
    };
    /**
     * @description: 确定
     * @return {*}
     */
    const handleOk = async () => {
        const values = await form.validateFields();
        values.status = values.status ? '0' : '1';
        // 如果是编辑，则进行编辑 API 请求
        if (currentRecord) {
            // 编辑
            values.id = currentRecord.id
            Ajax.post('/store-service/update', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        } else {
            // 新增
            Ajax.post('/store-service/create', values).then(body => {
                if (body.code === 0) {
                    message.success(body.data);
                    setVisible(false);
                    fetch(pagination);
                }
            });
        }
        setFileList([]);
        setVisible(false);
    };
    /**
     * @description: 取消
     * @return {*}
     */
    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: ServiceData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认删除
     * @return {*}
     */    
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/store-service/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>选择图片</div>
        </div>
    );
    const fileChange: UploadProps['onChange'] = ({ fileList: newFileList, file }) => {
        setFileList(newFileList);
        if (file.status === 'done' && file.response) {
            if (file.response.code === 0) {
                form.setFieldsValue({ fileName: file.response.data });
                message.success(`文件上传成功: ${file.response.data}`);
            } else {
                message.error(`文件上传失败: ${file.response.msg}`);
            }
        }
    };
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/store-service/page/' + pagination.current, { name: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <h1>服务管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入服务名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button type="primary" className="buttonSpace" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
                <Button className="buttonSpace" type='primary' onClick={handleAdd}>新增</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                rowKey="id"
            />
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除服务名称【{selectedRowKeys.map(key => {
                        const user = data.find(user => user.id === key);
                        return user ? user.name : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal title={modalTitle} open={visible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" width={1200}>
                <Form form={form} layout="horizontal" style={{ margin: '0 60px' }}>
                    <Row gutter={24}>
                        {currentRecord && (
                            <Col span={12}>
                                <Form.Item name="seq" label="序号" rules={[{ required: false, message: '请输入序号' }]}>
                                    <InputNumber step={1} placeholder="请输入序号" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item name="name" label="服务名称" rules={[{ required: true, message: '请输入服务名称' }]} >
                                <Input placeholder="请输入服务名称" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="details" label="服务描述" rules={[{ required: true, message: '请输入服务描述' }]}>
                                <TextArea rows={4} placeholder="请输入服务描述"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="remark" label="备注" rules={[{ required: false, message: '请输入备注信息' }]}>
                                <TextArea rows={4} placeholder="请输入备注信息"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="fileName" label="服务icon">
                                <div>
                                    <Upload
                                        action="/minio/file/upload"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={fileChange}
                                    >
                                        {fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                    <Image
                                        src={previewImage}
                                        style={{ display: 'none' }}
                                        preview={{ visible: previewOpen,onVisibleChange: (visible) => setPreviewOpen(visible) }}
                                    />
                                    {isAdding && <span style={{ color: 'red', display: 'block' }}>推荐尺寸 100*100</span>}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default ServiceData;