import {
    UserOutlined,
    BlockOutlined,
    DesktopOutlined,
    TeamOutlined,
    ApartmentOutlined,
    AppstoreOutlined,
    LikeOutlined,
    HeartOutlined,
    PictureOutlined,
    ShopOutlined,
    FileTextOutlined,
    TagOutlined
} from '@ant-design/icons';
import Account from "../pages/user/account";
import Store from "../pages/store";
import Service from "../pages/store/service";
import ServiceType from "../pages/store/service-type";
import Product from "../pages/store/product";
import Banner from "../pages/banner";
import Coupon from "../pages/coupon";
import React from "react";
import Role from "../pages/user/role";
import Index from "../pages/index";
import OrderManagement from "../pages/orders";
import EvaluationManagement from "../pages/eval"; 

/**
 * 菜单路由设置
 */
export default [
    {
        path: '/',
        name: '首页',
        icon: <DesktopOutlined/>,
        component: Index
    },
    {
        path: '/user',
        name: '门店用户',
        icon: <TeamOutlined/>,
        routes: [
            {
                component: Account,
                icon: <UserOutlined/>,
                resId: 1,
                name: '账户管理',
                path: '/user/account'
            },
            {
                component: Role,
                icon: <BlockOutlined/>,
                resId: 2,
                name: '角色管理',
                path: '/user/role'
            },
        ]
    },
    {
        resId: 3,
        path: '/store',
        name: '门店管理',
        icon: <ShopOutlined />,
        component: Store
    },
    {
        resId: 4,
        path: '/store/service',
        name: '导航管理',
        icon: <HeartOutlined />,
        component: Service
    },
    {
        resId: 5,
        path: '/store/service-type',
        name: '分类管理',
        icon: <ApartmentOutlined />,
        component: ServiceType
    },
    {
        resId: 6,
        path: '/store/product',
        name: '产品管理',
        icon: <AppstoreOutlined />,
        component: Product
    },
    {
        resId: 7,
        path: '/orders',
        name: '订单管理',
        icon: <FileTextOutlined />,
        component: OrderManagement
    },
    {
        resId: 8,
        path: '/eval',
        name: '评价管理',
        icon: <LikeOutlined />,
        component: EvaluationManagement
    },
    {
        resId: 14,
        path: '/banner',
        name: 'banner管理',
        icon: <PictureOutlined />,
        component: Banner
    },
    {
        resId: 16,
        path: '/coupon',
        name: '优惠券管理',
        icon: <TagOutlined />,
        component: Coupon
    }
]

