import React from 'react';
import {Button, Form, Input, message, InputNumber, Row, Col, Select, DatePicker, Switch} from "antd";
import {getRoleTree} from "../../../common/array";
import Ajax from "../../../api/Ajax";
import { log } from 'console';



interface UserAdd {
    id: number;
    username: string;
    password: string;
    name: string;
    sex: string;
    birthday: string;
    age: string;
    phone: string;
    post: string;
    status: string;
    seq: number;
    remark: string;
    roleIds: [];
    storeIds: [];
}
const UserAdd = porps => {
    const { TextArea } = Input;
    const sexOption = [
        { label: '男', value: '男' },
        { label: '女', value: '女' }
    ];
    const onFinish = values => {
        // 传值转换
        if (values.birthday) {
            values.birthday = values.birthday.format('YYYY-MM-DD');
        }
        if (values.age !== undefined) {
            values.age = String(values.age);
        }
        values.status = values.status ? '0' : '1';

        console.log('===onFinish==',values);
        
        Ajax.post('/user/create', values).then(body => {
            if (body.code === 0) {
                message.success(body.data);
                window.location.href = '/#/user/account';
            }
        });
    }
    return (
        <div>
            <h1>账户新增</h1>
            <Form layout="horizontal" style={{ margin: '0 60px' }} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item name="username" label="登录账户" rules={[{ required: true, message: '请输入登录账户' }]} >
                            <Input placeholder="请输入登录账户" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="password" label="登录密码" rules={[{ required: true, message: '请输入登录密码' }]}>
                            <Input.Password placeholder="请输入登录密码" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="name" label="姓名" rules={[{ required: false, message: '请输入登录姓名' }]} >
                            <Input placeholder="请输入登录姓名" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="sex" label="性别" rules={[{ required: false, message: '请选择性别' }]}>
                            <Select placeholder="请选择性别" allowClear options={sexOption}></Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="birthday" label="生日" rules={[{ required: false, message: '请输入生日日期' }]} >
                            <DatePicker format="YYYY-MM-DD" placeholder="请输入生日日期"  style={{width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="age" label="年龄" rules={[{ required: false, message: '请输入年龄' }]}>
                            <InputNumber step={1} precision={0} placeholder="请输入年龄" style={{width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="phone" label="电话" rules={[{ required: false, message: '请输入电话' }, { pattern: /^\d{11}$/, message: '电话格式不正确，应为11位数字' }]} >
                            <Input placeholder="请输入电话" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="post" label="职位" rules={[{ required: false, message: '请输入职位' }]}>
                            <Input placeholder="请输入职位" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="status" label="账户状态" valuePropName="checked" initialValue={true} rules={[{ required: false, message: '请选择账户状态' }]}>
                            <Switch checkedChildren="正常" unCheckedChildren="禁用" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="seq" label="账户序号" rules={[{ required: false, message: '请输入账户序号' }]}>
                            <InputNumber step={1} precision={0} placeholder="请输入账户序号" style={{width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="remark" label="备注" rules={[{ required: false, message: '请输入备注信息' }]}>
                            <TextArea rows={4} placeholder="请输入备注信息"/>
                        </Form.Item>
                    </Col>
                </Row>
                <div  className="container">
                    <div className="buttonGroup">
                        <Button className="buttonSpace" type='primary' htmlType="submit">确定</Button>
                        <Button className="buttonSpace"  href='/#/user/account'>返回</Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default UserAdd;
