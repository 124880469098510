import React, { useEffect, useState, Fragment } from 'react';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Table, message, Button,  Modal, Tag, Tabs, Input } from "antd";
import Ajax from "../../api/Ajax";
import "./index.less";

interface ProductData {
    id: number;
    seq: number;
    name: string;
    serviceId: number;
    serviceName: string;
    serviceTypeId: number;
    serviceTypeName: string;
    price: string;
    remark: string;
    details: string;
    discount: number;
    createTime: Date;
}
const ProductData = props => {
    const { Search } = Input;
    const { TabPane } = Tabs;
    const [data, setData] = useState<ProductData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        total: 100,
        showSizeChanger: false
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const canDelete = selectedRowKeys.length > 0;
    const [activeTab, setActiveTab] = useState<string>('all');
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
    const [completeVisible, setCompleteVisible] = useState<boolean>(false);
    const [cancelVisible, setCancelVisible] = useState<boolean>(false);
    /**
     * @description: 表设置
     * @return {*}
     */    
    const columns: ColumnsType<ProductData> = [
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            fixed: 'left',
            width: 60
        },
        {
            title: '订单号(ID)',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            fixed: 'left',
            width: 100
        },
        {
            title: '微信订单号',
            dataIndex: 'transactionId',
            key: 'transactionId',
            align: 'center',
            width: 260
        },
        {
            title: '下单时间',
            dataIndex: 'payTime',
            key: 'payTime',
            align: 'center',
            width: 200
        },
        {
            title: '订单状态',
            key: 'payStatus',
            dataIndex: 'payStatus',
            align: 'center',
            width: 100,
            render: (status) => {
                let color;
                let text;
        
                switch (status) {
                    case '1':
                        color = 'orange';
                        text = '待确认';
                        break;
                    case '3':
                        color = 'red';
                        text = '已取消';
                        break;
                    case '5':
                        color = 'blue';
                        text = '服务中';
                        break;
                    case '6':
                        color = 'green';
                        text = '已完成';
                        break;
                    default:
                        color = 'gray';
                        text = '未知状态';
                }
                return (
                    <Tag color={color}>
                        {text}
                    </Tag>
                );
            }
        },
        {
            title: '产品ID',
            dataIndex: 'productId',
            key: 'productId',
            align: 'center',
            width: 100
        },
        {
            title: '产品名称',
            dataIndex: 'productName',
            key: 'productName',
            align: 'center',
            width: 200
        },
        {
            title: '门店ID',
            dataIndex: 'storeId',
            key: 'storeId',
            align: 'center',
            width: 100
        },
        {
            title: '门店名称',
            dataIndex: 'storeName',
            key: 'storeName',
            align: 'center',
            width: 200
        },
        {
            title: '联系人',
            dataIndex: 'customerName',
            key: 'customerName',
            align: 'center',
            width: 100
        },
        {
            title: '联系人电话',
            dataIndex: 'customerPhone',
            key: 'customerPhone',
            align: 'center',
            width: 200
        },
        {
            title: '联系人地址',
            dataIndex: 'customerAddress',
            key: 'customerAddress',
            align: 'center',
            width: 200
        },
        {
            title: '预约服务时间',
            dataIndex: 'serviceTime',
            key: 'serviceTime',
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            fixed: 'right',
            width: 100,
            render: (text, record) => {
                if (activeTab === 'all') {
                    return null; 
                }
                return (
                    <Fragment>
                        {activeTab === 'pending' && (
                            <Button size='small' type='link' onClick={() => { setConfirmVisible(true); setSelectedRowKeys([record.id]) }}>确认</Button>
                        )}
                        {activeTab === 'in-progress' && (
                            <>
                                <Button size='small' type='link' onClick={() => { setCompleteVisible(true); setSelectedRowKeys([record.id]) }}>完成</Button>
                                <Button size='small' type='link' onClick={() => { setCancelVisible(true);setSelectedRowKeys([record.id]) }}>取消</Button>
                            </>
                        )}
                    </Fragment>
            );}
        }
    ];
    /**
     * 表格变化调用的接口
     * @param pagination 分页属性
     */
    const handleTableChange = (pagination) => {
        setPagination({ ...pagination });
        fetchData('/orders/page/', pagination);
    };

    // 刚进入页面时访问一次数据
    useEffect(() => {
        fetchAllOrders();
        // eslint-disable-next-line
    }, [])
    const fetchData = async (url: string, pagination) => {
        setLoading(true);
        try {
            const body = await Ajax.post(url + pagination.current);
            const currentData = body.data.records;
            currentData.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(currentData);
            setPagination({ ...pagination, total: body.data.total });
        } catch (error) {
            message.error("数据加载失败");
        } finally {
            setLoading(false);
        }
    };

    const fetchAllOrders = (pagination = { current: 1 }) => {
        fetchData('/orders/page/', pagination);
    };

    const fetchPendingOrders = (pagination = { current: 1 }) => {
        fetchData('/orders/page/wating/', pagination);
    };

    const fetchInProgressOrders = (pagination = { current: 1 }) => {
        fetchData('/orders/page/using/', pagination);
    };

    const handleTabChange = (key: string) => {
        setActiveTab(key);
        setSelectedRowKeys([]); // 清空选中
        setPagination({ ...pagination, current: 1 }); // 重置分页为1
        if (key === 'all') {
            fetchAllOrders();
        } else if (key === 'pending') {
            fetchPendingOrders();
        } else if (key === 'in-progress') {
            fetchInProgressOrders();
        }
    };
    /**
     * 获取分页数据
     * @param pagination 分页属性
     */
    const fetch = (pagination) => {
        setLoading(true);
        Ajax.get('/orders/page/' + pagination.current).then(body => {
            const data = body.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: body.data.total });
            setLoading(false);
        });
    }
    /**
     * @description: 行项目多选
     * @return {*}
     */    
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys); 
        },
        getCheckboxProps: (record: ProductData) => ({
            id: String(record.id), 
        }),
    };
    /**
     * @description: 确认删除
     * @return {*}
     */    
    const deleteHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/orders/delete?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setDeleteVisible(false);
                setActiveTab('all');
                fetch({...pagination, current: 1});
            }
        } catch (error) {
            message.error("删除失败");
        } finally {
            setDeleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]); // 清空选中项
        }
    };
    const giveHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/orders/give?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setConfirmVisible(false);
                setActiveTab('in-progress');
                fetchInProgressOrders();
            }
        } catch (error) {
            message.error("确认失败");
        } finally {
            setConfirmVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]);
        }
    };

    const overHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/orders/over?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setCompleteVisible(false);
                setActiveTab('all');
                fetchAllOrders();
            }
        } catch (error) {
            message.error("完成失败");
        } finally {
            setCompleteVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]);
        }
    };

    const giveUpHandleOk = async () => {
        setConfirmLoading(true);
        try {
            const params = selectedRowKeys.join(',');
            const body = await Ajax.post(`/orders/giveup?ids=${params}`);
            if (body.code === 0) {
                message.success(body.msg);
                setCancelVisible(false);
                setActiveTab('all');
                fetchAllOrders();
            }
        } catch (error) {
            message.error("取消失败");
        } finally {
            setCancelVisible(false);
            setConfirmLoading(false);
            setSelectedRowKeys([]);
        }
    };
    /**
     * @description: 查询事件
     * @param {string} value
     * @return {*}
     */    
    const onSearch = async (value: string) => {
        setLoading(true);
        try {
            const response = await Ajax.post('/orders/page/' + pagination.current, { transactionId: value.trim() });
            const data = response.data.records;
            data.forEach((item, index) => {
                item.key = index;
                item.order = index + 1;
            });
            setData(data);
            setPagination({ ...pagination, total: response.data.total, current: 1 });
        } catch (error) {
            message.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <h1>订单管理</h1>
            <div className="tableTopButtons">
                <Search placeholder="请输入类型名称" onSearch={onSearch} style={{ width: 300, marginRight: 'auto' }} />
                <Button type="primary" className="buttonSpace" disabled={!canDelete} onClick={() => setDeleteVisible(true)}>删除</Button>
            </div>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="所有订单" key="all">
                    <Table
                        dataSource={data}
                        columns={columns}
                        loading={loading}
                        pagination={pagination}
                        onChange={handleTableChange}
                        rowSelection={rowSelection}
                        rowKey="id"
                        scroll={{ x: 'max-content' }}
                    />
                </TabPane>
                <TabPane tab="待确认订单" key="pending">
                    <Table
                        dataSource={data}
                        columns={columns}
                        loading={loading}
                        pagination={pagination}
                        onChange={handleTableChange}
                        rowSelection={rowSelection}
                        rowKey="id"
                        scroll={{ x: 'max-content' }}
                    />
                </TabPane>
                <TabPane tab="服务中订单" key="in-progress">
                    <Table
                        dataSource={data}
                        columns={columns}
                        loading={loading}
                        pagination={pagination}
                        onChange={handleTableChange}
                        rowSelection={rowSelection}
                        rowKey="id"
                        scroll={{ x: 'max-content' }}
                    />
                </TabPane>
            </Tabs>
            <Modal
                title="删除"
                visible={deleteVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={deleteHandleOk}
                onCancel={() => {setDeleteVisible(false)}}
            >
                确定要删除订单号【{selectedRowKeys.map(key => {
                        const user = data.find(user => user.id === key);
                        return user ? user.id : null;
                    }).filter(name => name).join(', ')
                }】吗？
            </Modal>
            <Modal
                title="确认订单"
                visible={confirmVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={giveHandleOk}
                onCancel={() => setConfirmVisible(false)}
            >
                确定要确认订单号【{selectedRowKeys.join(', ')}】吗？
            </Modal>
            <Modal
                title="完成订单"
                visible={completeVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={overHandleOk}
                onCancel={() => setCompleteVisible(false)}
            >
                确定要完成订单号【{selectedRowKeys.join(', ')}】吗？
            </Modal>
            <Modal
                title="取消订单"
                visible={cancelVisible}
                confirmLoading={confirmLoading}
                cancelText='取消'
                okText='确认'
                onOk={giveUpHandleOk}
                onCancel={() => setCancelVisible(false)}
            >
                确定要取消订单号【{selectedRowKeys.join(', ')}】吗？
            </Modal>
        </div>
    );
};

export default ProductData;